.app__photography {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__photography-card {
    margin-top: 2.5rem ;
    margin-bottom: 1rem;
    margin: auto;
    padding: 2.5rem 0;
    width: 80%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: rgba(104, 180, 217, 0.3);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

    .app__photography-item {
        width: 80%;
        img {
            border-radius: 15px;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
            height: 700px;
        }
    }

    @media screen and (min-width: 2000px){
        width: 90%;
        height: 90%;
        .app__photography-item {
            img {
                height: 800px;
            }
        }
    }

    @media screen and (max-width: 1500px){
        width: 90%;
        height: 80%;
        margin-top: 1.5rem;

        .app__photography-item {
            
            img {
                height: 500px;
            }
        }
    }

    @media screen and (max-width: 768px){
        width: 90%;
        height: 70%;
        margin-top: 1.25rem;

        .app__photography-item {
            
            img {
                height: 400px;
            }
        }
    }

    @media screen and (max-width: 500px){
        width: 90%;
        height: 70%;
        padding: 0;

        .app__photography-item {
            
            img {
                height: 250px;
            }
        }
    }
}



.app__photography-btns {
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    bottom: 45%;

    div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--white-color);

        margin: 1rem;

        transition: all 0.3s ease-in-out;

        svg {
            width: 20px;
            height: 20px;

            color: var(--secondary-color);
        }

        &:hover {
            background-color: var(--secondary-color);

            svg{
                color: var(--white-color);
            }
        }
    }
}
