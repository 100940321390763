#home{
    position: relative;
    background: url('../../assets/bgIMG.png');
    background-size:  cover;
    background-repeat: repeat;
     
    background-position: center;
    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and ( min-width: 2000px){
        padding-top: 8rem;
    }

    @media screen and ( max-width: 1200px){
        flex-direction: column;
    }

    @media screen and ( max-width: 450px){
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;

    @media screen and ( max-width: 2000px){
        width: 100%;
        margin-right: 1rem;
    }

    @media screen and (max-width: 768px) {
        margin-right: 0;
    }
}

.app__header-badge {
    width: 80%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        
        background-color: var(--white-color);
        opacity: 0.7;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    .badge-cmp {
        span {
            font-size: 2.5rem;

            @media screen and (min-width: 2000px){
                font-size: 5rem;
            }
        } //span
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 2rem;

        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }

        @media screen and (max-width: 1200px){
            justify-content: flex-start;
            align-items: flex-start;
        }
    } //tag-cmp
} //app header-badge

.app__header-btn {
    margin-top: auto;
    margin-bottom: 13rem;
    align-self: flex-end;
    a {
        padding: 1rem 1.5rem;
        border-radius: 10px;
        font-size: 1rem;
        font-weight: 800;
        background-color: var(--white-color);
        color: var(--secondary-color);
        text-decoration: none;

        cursor: pointer;
        text-transform: uppercase;

        border: none;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        &:hover {
            color: var(--white-color);
            background-color: var(--secondary-color);
        } 
    }

    @media screen and (max-width: 900px){
        display: none;
    }

    @media screen and (max-width: 1200px){
        align-self: flex-start;
        margin-bottom: 0;

        a {
            position: relative;
            bottom: 2rem;
            left: 100%;
            font-size: 0.75rem;
        }

    }

}

.app__header-circles {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div { 
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-left: 1.5rem;

        background-color: var(--white-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1) {
        width: 100px;
        height: 100px;
    }
    div:nth-child(2) {
        margin: 3rem 3.5rem;
        width: 110px;
        height: 110px;
    }
    div:nth-child(3) {
        width: 85px;
        height: 85px;
    }

    @media screen and (min-width: 2000px){
        div:nth-child(1) {
            width: 120px;
            height: 120px;
        }
        div:nth-child(2) {
            width: 150px;
            height: 150px;
        }
        div:nth-child(3) {
            width: 100px;
            height: 100px;
        }
    }

    @media screen and (max-width: 1200px){
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div{
            margin: 1rem;
        }
    }
} //header-circles

.app__header-img {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 8px;
    padding-bottom: 1rem;

    img{
        width: 100%;
        object-fit: contain;
        z-index: 1;
        border-radius: 45%;
    }

    @media screen and (max-width: 1200px){
        margin: 2rem 0;
        left: 0;
        img{
            width: 100%;
        }
    }
}
